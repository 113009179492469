import { AudioSample } from "./audio.js";
import { Streak, SetSelector } from "./data.js";
import { SECOND, formatTime } from "./time.js";

const inhale_audio = new AudioSample("breathing/inhale.mp3");
const exhale_audio = new AudioSample("breathing/exhale.mp3");
const last_inhale_audio = new AudioSample("breathing/last_inhale.mp3");
const last_exhale_audio = new AudioSample("breathing/last_exhale.mp3");
const hold_audio = new AudioSample("breathing/hold.mp3");
const completed_audio = new AudioSample("breathing/completed.mp3");
const countdown_audio = getCounterAudio();
const rhythm_audio = new AudioSample("breathing/rhythm.mp3");

const streak = new Streak();
const sets = new SetSelector();

class BreathingPattern {
  setMethod(method) {
    this.data = method;
    sets.getSets(this);
  }

  addAnimation(animation_obj) {
    this.animation = animation_obj;
  }

  incrementSets() {
    if (this.data.num_of_sets < 50) {
      this.data.num_of_sets++;
      sets.updateSetValue(this);
      return 1;
    } else {
      return 0; // Maxed out
    }
  }

  decrementSets() {
    if (this.data.num_of_sets > 1) {
      this.data.num_of_sets--;
      sets.updateSetValue(this);
      return 1;
    } else {
      return 0; // Minimum reached
    }
  }

  infiniteStep() {
    if (this.end_infinite_part) {
      this.end_infinite_part = false;

      this.infinite_step_check_callbacks.forEach((callback) => {
        callback(false);
      });

      return false;
    } else {
      const is_infinite = this.getStepDuration() === -1 ? true : false;

      this.infinite_step_check_callbacks.forEach((callback) => {
        callback(is_infinite);
      });

      return is_infinite;
    }
  }

  addInfiniteStepCheckCallback(callback) {
    if (!this.infinite_step_check_callbacks) {
      this.infinite_step_check_callbacks = [];
    }

    this.infinite_step_check_callbacks.push(callback);

    return this.infinite_step_check_callbacks.length - 1; // Callback ID
  }

  removeInfiniteStepCheckCallback(id) {
    this.infinite_step_check_callbacks[id] = () => {};
  }

  async run() {
    this.cur_set = 0;
    this.cur_part = 0;
    this.cur_part_loop = 0;
    this.cur_step = 0;
    this.step_countdown = this.getStepDuration();

    this.calcSecondsTillEnd();

    this.running = true;
    this.controller = setInterval(async () => {
      if (this.running) {
        if (!this.infiniteStep()) {
          this.getTimeLeft();

          if (this.step_countdown < 1) {
            /* Next step */
            this.cur_step++;

            if (this.cur_step === this.data.parts[this.cur_part].steps.length) {
              /* Next loop */
              this.cur_step = 0;
              this.cur_part_loop++;

              if (
                this.cur_part_loop ===
                this.data.parts[this.cur_part].num_of_loops
              ) {
                /* Next part */
                this.cur_part_loop = 0;
                this.cur_part++;

                if (this.cur_part === this.data.parts.length) {
                  /* Next set */
                  this.cur_part = 0;
                  this.cur_set++;
                  this.callSetUpdateCallbacks();

                  if (this.cur_set === this.data.num_of_sets) {
                    /* Exercise done */
                    this.running = false;
                    streak.logExercise();
                    await completed_audio.play();
                    this.stop();
                    this.exercise_completed_callbacks.forEach((callback) => {
                      callback();
                    });
                  }
                }
              }
            }

            this.step_countdown = this.getStepDuration();
          }
        }

        if (this.running) {
          if (this.step_countdown === this.getStepDuration()) {
            /* Step started */
            switch (this.data.parts[this.cur_part].steps[this.cur_step].type) {
              case "inhale":
                if (this.notifyAboutPartEnd()) {
                  await last_inhale_audio.play();
                } else {
                  await inhale_audio.play();
                }

                if (this.getStepDuration() > 1) {
                  /* Step is long enough to play background music */
                  await this.playBreathingRhythmAudio(this.getStepDuration());
                }

                if (this.animation)
                  if (this.data.parts[this.cur_part].animation_loop_counter) {
                    this.animation.animateInhale(
                      this.getStepDuration(),
                      this.getPartLoopsLeft()
                    );
                  } else {
                    this.animation.animateInhale(this.getStepDuration());
                  }
                break;
              case "exhale":
                if (this.notifyAboutPartEnd()) {
                  last_exhale_audio.play();
                } else {
                  await exhale_audio.play();
                }

                if (this.getStepDuration() > 1) {
                  /* Step is long enough to play background music */
                  await this.playBreathingRhythmAudio(this.getStepDuration());
                }

                if (this.animation)
                  if (this.data.parts[this.cur_part].animation_loop_counter) {
                    this.animation.animateExhale(
                      this.getStepDuration(),
                      this.getPartLoopsLeft()
                    );
                  } else {
                    this.animation.animateExhale(this.getStepDuration());
                  }
                break;
              case "hold":
                await hold_audio.play();
                if (this.animation)
                  this.animation.animateHold(
                    this.getStepDuration(),
                    this.getStepDuration() > 0 /* Progress bar enabled? */
                  );
                break;
              default:
            }
          } else if (!this.infiniteStep()) {
            /* Countdown to end */
            await countdown_audio[this.step_countdown].play();
          }

          this.step_countdown--;
        }
      }
      this.seconds_till_end--;
    }, SECOND);
  }

  addSetUpdateCallback(callback) {
    if (!this.set_update_callback) {
      this.set_update_callback = [];
    }

    this.set_update_callback.push(callback);

    return this.set_update_callback.length - 1; // callback ID
  }

  removeSetUpdateCallback(callback_ID) {
    this.set_update_callback[callback_ID] = () => {};
  }

  callSetUpdateCallbacks() {
    if (this.set_update_callback) {
      this.set_update_callback.forEach((callback) => {
        callback();
      });
    }
  }

  getPartLoopsLeft() {
    return this.data.parts[this.cur_part].num_of_loops - this.cur_part_loop;
  }

  notifyAboutPartEnd() {
    if (
      this.cur_part_loop === this.data.parts[this.cur_part].num_of_loops - 1 &&
      this.data.parts[this.cur_part].notify_end
    ) {
      return true;
    } else {
      return false;
    }
  }

  getStepDuration() {
    return this.data.parts[this.cur_part].steps[this.cur_step].duration;
  }

  getTimeLeft() {
    const time_left = formatTime(this.seconds_till_end).txt;

    if (this.time_left_callbacks) {
      this.time_left_callbacks.forEach((callback) => {
        callback(time_left);
      });
    }

    return time_left;
  }

  calcSecondsTillEnd(
    cur_step = 0,
    cur_part = 0,
    cur_part_loop = 0,
    cur_set = 0
  ) {
    this.seconds_till_end = 0;

    let part = cur_part;
    let part_loop = cur_part_loop;
    let step = cur_step;

    for (; cur_set < this.data.num_of_sets; cur_set++) {
      for (; part < this.data.parts.length; part++) {
        for (; part_loop < this.data.parts[part].num_of_loops; part_loop++) {
          for (; step < this.data.parts[part].steps.length; step++) {
            this.seconds_till_end += this.data.parts[part].steps[step].duration;
          }

          step = 0; // New part loop
        }

        part_loop = 0; // New part
      }

      part = 0; // New set
    }

    return this.seconds_till_end;
  }

  endInfinitePart() {
    this.end_infinite_part = true;
  }

  addTimeLeftCallback(callback) {
    if (!this.time_left_callbacks) {
      this.time_left_callbacks = [];
    }

    this.time_left_callbacks.push(callback);

    return this.time_left_callbacks.length - 1; // Callback ID
  }

  removeTimeLeftCallback(id) {
    this.time_left_callbacks[id] = () => {};
  }

  addExerciseCompletedCallback(callback) {
    if (!this.exercise_completed_callbacks) {
      this.exercise_completed_callbacks = [];
    }

    this.exercise_completed_callbacks.push(callback);

    return this.exercise_completed_callbacks.length - 1;
  }

  removeExerciseCompletedCallback(id) {
    this.exercise_completed_callbacks[id] = () => {};
  }

  async playBreathingRhythmAudio(duration) {
    this.rhythm_audio_enabled = true;

    /* If user inhales/exhales then app plays background music. This music will fade out. If audio is set to fade out, then audio is also stopped. If inhale comes right after exhale then background music will start to play because of inhale, but exhale audio finishes a tiny bit later and stops inhale background music (inhale and exhale is played using the same audio file). For this reason, rhythm audio file is set to skip stop function. However, if user disables audio from settings then this skip_stop value is set to false  */
    rhythm_audio.skip_stop = true;

    await rhythm_audio.play();
    rhythm_audio.fadeIn();
    rhythm_audio.fadeOut(duration - 1, 1);
  }

  pause() {
    this.running = false;
    this.animation.stop();
  }

  resume() {
    this.calcSecondsTillEnd(
      this.cur_step,
      this.cur_part,
      this.cur_part_loop,
      this.cur_set
    );
    this.step_countdown = this.getStepDuration();
    this.running = true;
  }

  stop() {
    clearInterval(this.controller);
    this.running = false;
    if (this.animation) this.animation.stop();
    if (this.rhythm_audio_enabled) rhythm_audio.fadeOut();
  }
}

function getCounterAudio() {
  const NUM_OF_COUNTER_FILES = 20;
  let counter_audio = new Array(NUM_OF_COUNTER_FILES + 1); // Counting starts from 1!

  for (let i = 1; i <= NUM_OF_COUNTER_FILES; i++) {
    counter_audio[i] = new AudioSample(`count/${i}.mp3`);
  }

  return counter_audio;
}

export { BreathingPattern, getCounterAudio };
