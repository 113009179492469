const calm = {
  name: "Relax",
  desc: 'This exercise has been called "natural tranquilizer for the nervous system" by Dr. Andrew Weil, easing the body into a state of calmness and relaxation.',
  parts: [
    {
      steps: [
        { type: "inhale", duration: 4, airway: "nose" },
        { type: "hold", duration: 7 },
        { type: "exhale", duration: 8, airway: "mouth" },
      ],
      num_of_loops: 1,
    },
  ],
  num_of_sets: 10,
};

const power = {
  name: "Power",
  desc: "This exercise stimulates your sympathetic nervous system and trains your body to be more resilient toward psychical and mental stress.",
  caution:
    "Only perform this exercise by lying down in a safe environment. This exercise may cause lightheadedness.",
  parts: [
    {
      name: "Prepare",
      steps: [
        { type: "inhale", duration: 1, airway: "nose" },
        { type: "exhale", duration: 1, airway: "mouth" },
      ],
      num_of_loops: 30,
      notify_end: true,
      animation_loop_counter: true,
    },
    {
      name: "Hold",
      steps: [{ type: "hold", duration: -1 }],
      num_of_loops: 1,
    },
    {
      name: "Ease",
      steps: [
        { type: "inhale", duration: 5, airway: "nose" },
        { type: "hold", duration: 10 },
        { type: "exhale", duration: 5, airway: "mouth" },
      ],
      num_of_loops: 1,
    },
  ],
  num_of_sets: 3,
  duration_undefined: true,
};

const box = {
  name: "Box",
  desc: "Box breathing is a technique to heighten performance and concentration while also being a potent stress reliever. It's famously used by athletes, Navy SEALs and even yoga practitioners.",
  parts: [
    {
      steps: [
        { type: "inhale", duration: 4, airway: "nose" },
        { type: "hold", duration: 4 },
        { type: "exhale", duration: 4, airway: "mouth" },
        { type: "hold", duration: 4 },
      ],
      num_of_loops: 1,
    },
  ],
  num_of_sets: 10,
};

const exercises = [calm, box, power];
export default exercises;
