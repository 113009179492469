const EXERCISE_TIME_KEY = "last_exercise_time";
const MAX_STREAK_KEY = "max_streak";
const CURRENT_STREAK_KEY = "current_streak";
const EXTENDING_TIME_KEY = "extending_time";
const AUDIO_STATE_KEY = "audio_enabled";
const BREATH_HOLD_MAX_KEY = "breath_hold_max";
const SETS_VALUE_KEY = "_sets_value";
const HOLD_FEEDBACK_KEY = "hold_feedback";

const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 ms

const data = window.localStorage;

function parseStringNum(string) {
  const num = parseInt(string);
  return num ? num : null;
}

function stopAllAudio() {
  for (let i = 0; i < window.audio_files.length; i++) {
    if (window.audio_files[i].source) {
      window.audio_files[i].skip_stop = false; // Make sure that every audio file can be stopped
      window.audio_files[i].stop();
    }
  }
}

class Streak {
  logExercise() {
    data.setItem(EXERCISE_TIME_KEY, this.getEpochTimeUntilToday());
    this.extendStreak();
    this.runUpdateCallbacks();
  }

  getDaysSince(epoch_time) {
    if (epoch_time != null) {
      const time_dif = this.getEpochTimeUntilToday() - epoch_time;
      return Math.floor(time_dif / MILLISECONDS_PER_DAY);
    } else {
      return null;
    }
  }

  update() {
    const days_since_exercise = this.getDaysSince(
      parseStringNum(data.getItem(EXERCISE_TIME_KEY))
    );

    if (days_since_exercise > 1) {
      this.endStreak();
    }

    this.runUpdateCallbacks();
  }

  extendStreak() {
    const extending_time = parseStringNum(data.getItem(EXTENDING_TIME_KEY));

    if (
      extending_time === null ||
      this.getDaysSince(extending_time) === 1 ||
      this.getCurrent() === 0
    ) {
      /* User hasn't exercised before or the last time when streak was extended was yesterday */
      data.setItem(CURRENT_STREAK_KEY, this.getCurrent() + 1);
      data.setItem(EXTENDING_TIME_KEY, this.getEpochTimeUntilToday());
    }

    this.updateMaxStreak();
  }

  endStreak() {
    this.updateMaxStreak();
    data.setItem(CURRENT_STREAK_KEY, 0);
  }

  updateMaxStreak() {
    if (this.getCurrent() > this.getMax()) {
      data.setItem(MAX_STREAK_KEY, this.getCurrent());
    }
  }

  getEpochTimeUntilToday() {
    /* Get epoch time UNTIL the day started! This way it's easier to measure actual days between dates */
    const today = new Date();
    return new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    ).getTime();
  }

  getCurrent() {
    const current_streak = parseStringNum(data.getItem(CURRENT_STREAK_KEY));
    return current_streak ? current_streak : 0;
  }

  getMax() {
    const max_streak = parseStringNum(data.getItem(MAX_STREAK_KEY));
    return max_streak ? max_streak : 0;
  }

  reset() {
    data.setItem(EXERCISE_TIME_KEY, null);
    data.setItem(MAX_STREAK_KEY, null);
    data.setItem(CURRENT_STREAK_KEY, null);
    data.setItem(EXTENDING_TIME_KEY, null);
  }

  addUpdateCallback(callback) {
    if (!this.update_callbacks) {
      this.update_callbacks = [];
    }

    this.update_callbacks.push(callback);

    return this.update_callbacks.length - 1; // Callback ID
  }

  removeUpdateCallback(id) {
    this.update_callbacks[id] = () => {};
  }

  runUpdateCallbacks() {
    if (this.update_callbacks) {
      this.update_callbacks.forEach((callback) => {
        callback();
      });
    }
  }
}

class Settings {
  toggleAudio() {
    const audio_state = data.getItem(AUDIO_STATE_KEY);

    if (audio_state) {
      /* Storage has value about audio state */
      if (audio_state === "true") {
        data.setItem(AUDIO_STATE_KEY, false);

        stopAllAudio();

        return false;
      } else {
        data.setItem(AUDIO_STATE_KEY, true);
        return true;
      }
    } else {
      /* No value in storage, so create one */
      data.setItem(AUDIO_STATE_KEY, true);
      return true;
    }
  }

  isAudioEnabled() {
    const audio_state = data.getItem(AUDIO_STATE_KEY);

    if (audio_state) {
      /* Storage has value about audio state */
      if (audio_state === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      /* No value in storage, so create one */
      data.setItem(AUDIO_STATE_KEY, true);
      return true;
    }
  }

  toggleHoldFeedback() {
    const hold_feedback_state = data.getItem(HOLD_FEEDBACK_KEY);

    if (hold_feedback_state) {
      /* Storage has value about audio state */
      if (hold_feedback_state === "true") {
        data.setItem(HOLD_FEEDBACK_KEY, false);
        return false;
      } else {
        data.setItem(HOLD_FEEDBACK_KEY, true);
        return true;
      }
    } else {
      /* No value in storage, so create one */
      data.setItem(HOLD_FEEDBACK_KEY, true);
      return true;
    }
  }

  isHoldFeedbackEnabled() {
    const hold_feedback_state = data.getItem(HOLD_FEEDBACK_KEY);

    if (hold_feedback_state) {
      /* Storage has value about audio state */
      if (hold_feedback_state === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      /* No value in storage, so create one */
      data.setItem(HOLD_FEEDBACK_KEY, true);
      return true;
    }
  }
}

class BreathHold {
  getMax() {
    const max = parseStringNum(data.getItem(BREATH_HOLD_MAX_KEY));
    if (!max) {
      data.setItem(BREATH_HOLD_MAX_KEY, 0);
      return 0;
    } else {
      return max;
    }
  }

  update(new_time) {
    if (new_time > this.getMax()) {
      data.setItem(BREATH_HOLD_MAX_KEY, new_time);
    }
  }
}

class SetSelector {
  getSets(exercise) {
    const value = parseStringNum(
      data.getItem(exercise.data.name + SETS_VALUE_KEY)
    );
    if (value) {
      exercise.data.num_of_sets = value;
    }
  }

  updateSetValue(exercise) {
    data.setItem(exercise.data.name + "_sets_value", exercise.data.num_of_sets);
  }
}

export { Streak, Settings, BreathHold, SetSelector };
