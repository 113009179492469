class Canvas {
  constructor(canvas_element) {
    this.canvas = canvas_element;
    this.ctx = canvas_element.getContext("2d");
  }

  getCenter() {
    let pos = {};

    pos.x = this.canvas.width / 2;
    pos.y = this.canvas.height / 2;

    return pos;
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}

class Circle {
  constructor(canvas, radius, color = "black") {
    this.color = color;
    this.radius = radius;
    this.canvas = canvas;
    this.x = 0;
    this.y = 0;
    this.filled = true;
    this.arc_start_rad = 0;
    this.arc_end_rad = Math.PI * 2;
    this.border_enabled = true;
  }

  setCenter() {
    let pos = this.canvas.getCenter();
    this.x = pos.x;
    this.y = pos.y;
  }

  setBorder(color, width = 1) {
    this.border = {};
    this.border.color = color;
    this.border.width = width;
  }

  setOutline(color, width = 1) {
    this.outline = {};
    this.outline.color = color;
    this.outline.width = width;

    this.setBorder(color, 1);
  }

  setPos(x, y) {
    this.x = x;
    this.y = y;
  }

  setSize(radius) {
    this.radius = radius;
  }

  setArcEndRad(rad) {
    this.arc_end_rad = rad;
  }

  setArcStartRad(rad) {
    this.arc_start_rad = rad;
  }

  setEmpty(color, width = 1) {
    this.filled = false;
    this.setBorder(color, width);
  }

  enableBorder(status = true) {
    this.border_enabled = status;
  }

  drawOutline() {
    this.canvas.ctx.beginPath();
    this.canvas.ctx.arc(
      this.x,
      this.y,
      this.radius + this.outline.width,
      this.arc_start_rad,
      this.arc_end_rad
    );
    this.canvas.ctx.fillStyle = this.outline.color;
    this.canvas.ctx.fill();
  }

  drawBorder() {
    this.canvas.ctx.lineWidth = this.border.width;
    this.canvas.ctx.strokeStyle = this.border.color;
    this.canvas.ctx.stroke();
  }

  drawCircle() {
    this.canvas.ctx.beginPath();
    this.canvas.ctx.arc(
      this.x,
      this.y,
      this.radius,
      this.arc_start_rad,
      this.arc_end_rad
    );

    if (this.filled) {
      this.canvas.ctx.fillStyle = this.color;
      this.canvas.ctx.fill();
    }
  }

  draw() {
    if (this.outline) {
      this.drawOutline();
    }

    this.drawCircle();

    if (this.border && this.border_enabled) {
      this.drawBorder();
    }
  }

  grow(step, max_radius = 0) {
    if (max_radius) {
      if (this.radius + step < max_radius) {
        this.radius += step;
      } else {
        this.radius = max_radius;
      }
    } else {
      this.radius += step;
    }
  }

  shrink(step, min_radius = 0) {
    if (this.radius - step > 0) {
      if (min_radius) {
        if (this.radius > min_radius) {
          this.radius -= step;
        }
      } else {
        this.radius -= step;
      }
    }
  }
}

class Label {
  constructor(canvas, text, style = {}) {
    this.canvas = canvas;
    this.text = text;

    this.font = style.font || "arial";
    this.size = style.size || 15;
    this.x = style.x || 0;
    this.y = style.y || 0;
    this.color = style.color || "black";
    this.align = style.align || "left";
    this.baseline = style.baseline || "top";
    this.weight = style.weight || "300";
  }

  draw() {
    this.canvas.ctx.font =
      this.weight + " " + this.size.toString() + "px " + this.font;
    this.canvas.ctx.fillStyle = this.color;
    this.canvas.ctx.textAlign = this.align;
    this.canvas.ctx.textBaseline = this.baseline;
    this.canvas.ctx.fillText(this.text, this.x, this.y);
  }

  setCenter() {
    let pos = this.canvas.getCenter();
    this.x = pos.x;
    this.y = pos.y;
    this.align = "center";
    this.baseline = "middle";
  }

  setText(new_text) {
    this.text = new_text;
  }
}

export { Circle, Canvas, Label };
