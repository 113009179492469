const SECOND = 1000; // 1 second in ms
const MINUTE = 60; // 1 minute in seconds

function formatTime(time_in_seconds) {
  const time = {
    minutes: Math.floor(time_in_seconds / MINUTE),
    seconds: time_in_seconds % 60,
    txt: "",
  };

  if (time.minutes < 10) {
    time.txt += "0";
  }
  time.txt += time.minutes + ":";

  if (time.seconds < 10) {
    time.txt += "0";
  }
  time.txt += time.seconds;

  return time;
}

export { formatTime, SECOND, MINUTE };
