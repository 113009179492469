import { Settings } from "./data.js";

const SECOND = 1000;

const AudioContext = window.AudioContext || window.webkitAudioContext;
const audio_cxt = new AudioContext();

const settings = new Settings();

async function enableAudio() {
  if (audio_cxt.state === "suspended") {
    await audio_cxt.resume();
  }
}

class AudioSample {
  constructor(filepath) {
    this.audio = null;
    this.source = null;
    this.gain_node = null;
    this.filepath = this.completeFilepath(filepath);
    this.vol = 1; // Max volume
    this.getFile();

    /* If user inhales/exhales then app plays background music. This music will fade out. If audio is set to fade out, then audio is also stopped. If inhale comes right after exhale then background music will start to play because of inhale, but exhale audio finishes a tiny bit later and stops inhale background music (inhale and exhale is played using the same audio file). For this reason, every audio file can be set to skip stop function. However, if user disables audio from settings then this skip_stop value is set to false  */
    this.skip_stop = false;
  }

  completeFilepath(filepath) {
    return "assets/audio/" + filepath;
  }

  async getFile() {
    let file = await fetch(this.filepath);
    let data = await file.arrayBuffer();
    this.audio = await audio_cxt.decodeAudioData(data);

    window.audio_files.push(this);
  }

  async play() {
    if (settings.isAudioEnabled()) {
      await this.prepareToPlay();
      this.source.start();
    }
  }

  async prepareToPlay() {
    if (!this.audio) {
      await this.getFile();
    }

    if (this.source) {
      this.source.stop(); // "Destroy" previous instances of this object
      this.gain_node.gain.cancelScheduledValues(audio_cxt.currentTime);
    }

    this.source = audio_cxt.createBufferSource();
    this.source.buffer = this.audio;
    this.gain_node = audio_cxt.createGain();

    this.source.connect(this.gain_node);
    this.gain_node.connect(audio_cxt.destination);
  }

  fadeIn(duration = 1) {
    if (this.source) {
      this.gain_node.gain.setValueAtTime(0, audio_cxt.currentTime);
      this.gain_node.gain.linearRampToValueAtTime(
        this.vol,
        audio_cxt.currentTime + duration
      );
    }
  }

  fadeOut(fade_duration = 1, delay_duration = 0) {
    if (this.source) {
      setTimeout(() => {
        if (delay_duration) {
          this.gain_node.gain.setValueAtTime(this.vol, audio_cxt.currentTime); // Bug fix
        }

        this.gain_node.gain.linearRampToValueAtTime(
          0,
          audio_cxt.currentTime + fade_duration
        );

        this.stop(fade_duration); // Make sure that audio actually stops after fade out
      }, delay_duration * SECOND);
    }
  }

  setVol(volume) {
    if (this.source) {
      this.vol = this.gain_node.gain.value = volume; // 0 <= volume >= 1
    }
  }

  stop(seconds_till_stopping = 0) {
    setTimeout(() => {
      if (this.source && !this.skip_stop) {
        /* If user disables audio while audio file is playing
        then it's possible that after calling stop() method
        on audio file the audio is already stopped.
        Therefore it's important to make sure whether audio has stopped or not*/
        this.source.stop();
        this.source = null;
      }
    }, seconds_till_stopping * SECOND);
  }
}

export { AudioSample, enableAudio };
