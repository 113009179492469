import { Canvas, Circle, Label } from "./draw.js";

const DARK = "#232323";
const BLUE = "#216bff";
const ORANGE = "#FFBA21";

const FONT = "Roboto";

const BG_CIRCLE_RAD = 120;
const INNER_CIRCLE_RAD = 50;
const BREATH_CIRCLE_MIN_RAD = INNER_CIRCLE_RAD + 5;
const HOLD_CIRCLE_RAD = BG_CIRCLE_RAD;
const HOLD_CIRCLE_WIDTH = 20;

const SECOND = 1000;
const FPS = 60;

class BreathAnimation {
  setUp(canvas_el, display = false) {
    this.canvas = new Canvas(canvas_el);
    this.inner_circle = new Circle(this.canvas, INNER_CIRCLE_RAD, DARK);
    this.breath_circle = new Circle(this.canvas, BREATH_CIRCLE_MIN_RAD, BLUE);
    this.bg_circle = new Circle(this.canvas, BG_CIRCLE_RAD, DARK);
    this.breath_label = new Label(this.canvas, "", {
      size: 23,
      color: "white",
      font: FONT,
    });

    this.hold_circle = new Circle(this.canvas, HOLD_CIRCLE_RAD);
    this.hold_circle.setEmpty(ORANGE, HOLD_CIRCLE_WIDTH);

    this.inner_circle.setCenter();
    this.breath_circle.setCenter();
    this.bg_circle.setCenter();
    this.hold_circle.setCenter();
    this.breath_label.setCenter();

    this.breath_circle.setOutline(BLUE);

    if (display) {
      this.drawAllElements();
    }

    this.calcFrameLength();
    this.last_frame_time_ms = 0;

    this.next_frame_time = 0;

    this.mode = null; // Mode: "grow", "shrink" or "hold"
    this.hold_animation_enabled = true;
  }

  drawAllElements() {
    if (this.mode === "hold" && this.hold_animation_enabled) {
      this.hold_circle.draw();
    }

    this.bg_circle.draw();
    this.breath_circle.draw();
    this.inner_circle.draw();
    this.breath_label.draw();
  }

  draw(timestamp) {
    if (timestamp >= this.next_frame_time) {
      if (this.next_frame_time === 0) {
        /* First frame */
        this.next_frame_time = timestamp;
      }
      this.next_frame_time += this.frame_length_ms;

      this.canvas.clear();
      this.animateBreathCircle();
      this.drawAllElements();
    }

    this.animation_ID = requestAnimationFrame((timestamp) => {
      this.draw(timestamp);
    });
  }

  animateHoldProgress() {
    this.hold_circle.setArcEndRad(this.hold_progress_arc_len);
    this.hold_progress_arc_len += this.hold_animation_arc_step;
  }

  calcHoldFrameStep(hold_duration_ms) {
    const num_of_hold_frames = hold_duration_ms / this.frame_length_ms;
    this.hold_animation_arc_step = (2 * Math.PI) / num_of_hold_frames; // 2 * pi is full circle
  }

  animateBreathCircle() {
    switch (this.mode) {
      case "grow":
        this.breath_circle.grow(this.animation_step, BG_CIRCLE_RAD);
        break;
      case "shrink":
        this.breath_circle.shrink(this.animation_step, BREATH_CIRCLE_MIN_RAD);
        break;
      case "hold":
        this.animateHoldProgress();
        break;
      default:
        break;
    }
  }

  start(duration_ms) {
    this.calcAnimationStep(duration_ms);

    this.animation_ID = requestAnimationFrame((timestamp) => {
      this.draw(timestamp);
    });
  }

  calcAnimationStep(duration_ms) {
    let distance = BG_CIRCLE_RAD - BREATH_CIRCLE_MIN_RAD;
    let num_of_frames_till_new_part = duration_ms / this.frame_length_ms;
    this.animation_step = distance / num_of_frames_till_new_part; // px per frame
  }

  stop() {
    cancelAnimationFrame(this.animation_ID);
    this.mode = null; // Reset
  }

  animateInhale(duration_s, text = "Inhale") {
    this.stop();
    this.breath_circle.setSize(BREATH_CIRCLE_MIN_RAD);
    this.mode = "grow";
    this.breath_label.setText(text);
    this.start(duration_s * SECOND);
  }

  animateExhale(duration_s, text = "Exhale") {
    this.stop();
    this.breath_circle.setSize(BG_CIRCLE_RAD);
    this.mode = "shrink";
    this.breath_label.setText(text);
    this.start(duration_s * SECOND);
  }

  animateHold(duration_s, progress_bar_enabled = true) {
    this.stop();
    this.mode = "hold";
    this.breath_label.setText("Hold");

    if (progress_bar_enabled) {
      this.hold_animation_enabled = true;
      this.hold_circle.enableBorder();
      this.setHoldAnimationStartPos();
      this.calcHoldFrameStep(duration_s * SECOND);
    } else {
      this.hold_circle.enableBorder(false);
    }

    this.start();
  }

  setHoldAnimationStartPos() {
    this.hold_progress_arc_len = (3 / 2) * Math.PI;
    this.hold_circle.setArcStartRad(this.hold_progress_arc_len);
  }

  calcFrameLength() {
    this.frame_length_ms = (1 / FPS) * 1000;
  }
}

export { BreathAnimation };
