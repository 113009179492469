import React from "react";
import { motion } from "framer-motion";

import { Streak, Settings, BreathHold } from "./data.js";
import { BreathingPattern, getCounterAudio } from "./exercise.js";
import { BreathAnimation } from "./animations";
import { AudioSample, enableAudio } from "./audio.js";
import exercises from "./exercise_data.js";
import { SECOND, formatTime } from "./time.js";
import { ScreenAwake } from "./screenAwake.js";

import "normalize.css";
import "./index.css";

const exercise = new BreathingPattern();
const animation = new BreathAnimation();
exercise.addAnimation(animation);
const streak_data = new Streak();
const settings_data = new Settings();
const breath_hold_data = new BreathHold();
const screen_awake = new ScreenAwake();

function singleOrPlural(word, count) {
  return count === 1 ? word : word + "s";
}

class Fade extends React.Component {
  render() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        layoutId={this.props.layoutId}
        layout
      >
        {this.props.children}
      </motion.div>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exercise_open: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Fade>
          <Title />
        </Fade>
        {this.showContent()}
        <Fade>
          <Footer />
        </Fade>
      </React.Fragment>
    );
  }

  showContent() {
    if (this.state.exercise_open) {
      return <ExercisePage closeExercise={() => this.closeExercise()} />;
    } else {
      return <HomePage openExercise={(data) => this.openExercise(data)} />;
    }
  }

  openExercise(exercise_data) {
    exercise.setMethod(exercise_data);
    this.setState({ exercise_open: true });
  }

  closeExercise() {
    this.setState({ exercise_open: false });
    screen_awake.stop();
  }
}

function HomePage(props) {
  return (
    <Fade>
      <section className="section box_container">
        <StatsBox />
        <ExerciseList openExercise={(data) => props.openExercise(data)} />
        <AllSettings />
      </section>
    </Fade>
  );
}

function AllSettings() {
  return (
    <section className="box">
      <span className="h1">Settings</span>
      <SoundToggle />
      <BreathHoldFeedbackToggle />
    </section>
  );
}

function SoundToggle() {
  return (
    <Toggle
      label="Sound"
      on={settings_data.isAudioEnabled()}
      toggle={() => settings_data.toggleAudio()}
    />
  );
}

function BreathHoldFeedbackToggle() {
  return (
    <Toggle
      label="Breath Hold Feedback"
      on={settings_data.isHoldFeedbackEnabled()}
      toggle={() => settings_data.toggleHoldFeedback()}
      desc={
        "Audible announcement about how long you have held your breath during Power exercise."
      }
    />
  );
}

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: props.on,
    };
  }

  render() {
    return (
      <div className="toggle">
        <div className="switch_box" onClick={() => this.toggleSwitch()}>
          <span className={this.getSwitchState()}></span>
        </div>
        <div className="toggle_text">
          <span className="toggle_label">{this.props.label}</span>
          {this.props.desc && (
            <React.Fragment>
              <div className="line_break_flex"></div>
              <span className="toggle_desc">{this.props.desc}</span>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  getSwitchState() {
    if (this.state.on) {
      return "switch switch_on";
    } else {
      return "switch switch_off";
    }
  }

  toggleSwitch() {
    this.setState({ on: this.props.toggle() });
  }
}

function Title() {
  return (
    <section className="section">
      <span className="title">Nosu</span>
      <span className="subtitle">Breathing exercises</span>
    </section>
  );
}

function StatsBox() {
  return (
    <section className="box">
      <span className="h1">Stats</span>
      <div id="stats_container">
        <StreakCounter />
        <BreathHoldData />
      </div>
    </section>
  );
}

class StreakCounter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: streak_data.getCurrent(),
      max: streak_data.getMax(),
      streak_callback_id: streak_data.addUpdateCallback(() =>
        this.updateData()
      ),
    };
  }

  render() {
    return (
      <div className="stat_box">
        <span className="h2">Streak</span>
        <div className="data_container">
          <StreakData header="Current" count={this.state.current} />
          <StreakData header="Max" count={this.state.max} />
        </div>
      </div>
    );
  }

  componentDidMount() {
    streak_data.update();
  }

  componentWillUnmount() {
    let id = this.state.streak_callback_id;
    streak_data.removeUpdateCallback(id);
  }

  updateData() {
    this.setState({
      current: streak_data.getCurrent(),
      max: streak_data.getMax(),
    });
  }
}

function StreakData(props) {
  return (
    <div className="data">
      <span className="h4">{props.header} </span>
      <span className="data_value">
        {props.count} {singleOrPlural("day", props.count)}
      </span>
    </div>
  );
}

function BreathHoldData() {
  return (
    <div className="stat_box">
      <span className="h2">Breath Hold</span>
      <div className="data_container">
        <div className="data">
          <span className="h4">Max</span>
          <span className="data_value">
            {breath_hold_data.getMax()}
            {singleOrPlural(" second", breath_hold_data.getMax())}
          </span>
        </div>
      </div>
    </div>
  );
}

class ExerciseList extends React.Component {
  render() {
    return (
      <section className="box">
        <span className="h1">Practice</span>
        <div>{this.generateExerciseSelection()}</div>
      </section>
    );
  }

  generateExerciseSelection() {
    return exercises.map((data, i) => {
      return (
        <button
          className="btn exercise_selection_btn"
          onClick={() => this.openExercise(data)}
          key={i}
        >
          {data.name}
        </button>
      );
    });
  }

  openExercise(data) {
    enableAudio(); // Because iOS Safari
    this.props.openExercise(data);
  }
}

class ExercisePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercise_running: false,
      exercise_completed: false,
      completed_callback_id: exercise.addExerciseCompletedCallback(() =>
        this.launchExerciseCompletedWindow()
      ),
    };
  }

  render() {
    if (this.state.exercise_running) {
      return (
        <PracticeWindow closeExercise={() => this.props.closeExercise()} />
      );
    } else if (this.state.exercise_completed) {
      return (
        <ExerciseCompletedWindow
          closeExercise={() => this.props.closeExercise()}
        />
      );
    } else {
      return (
        <ExerciseIntro
          startExercise={() => this.startExercise()}
          closeExercise={() => this.props.closeExercise()}
        />
      );
    }
  }

  componentWillUnmount() {
    exercise.removeExerciseCompletedCallback(this.state.completed_callback_id);
  }

  startExercise() {
    this.setState({ exercise_running: true });
    exercise.run();
    screen_awake.start();
  }

  launchExerciseCompletedWindow() {
    this.setState({ exercise_completed: true, exercise_running: false });
  }
}

function ExerciseIntro(props) {
  return (
    <Fade>
      <section className="section">
        <button
          className="btn btn_red back_btn"
          onClick={() => props.closeExercise()}
        >
          Back
        </button>
        <div className="box" id="exercise_intro">
          <div>
            <span className="h1">{exercise.data.name}</span>
            <p>{exercise.data.desc}</p>
            <ExerciseCaution />
            <ExerciseGuide />
          </div>
          <ExerciseStartControl startExercise={() => props.startExercise()} />
        </div>
      </section>
    </Fade>
  );
}

function ExerciseCaution() {
  if (exercise.data.caution) {
    return (
      <div id="caution">
        <span className="h2">Caution</span>
        <div>{exercise.data.caution}</div>
      </div>
    );
  } else {
    return null;
  }
}

class ExerciseGuide extends React.Component {
  render() {
    return (
      <React.Fragment>
        <span className="h2">Technique</span>
        {this.getParts()}
      </React.Fragment>
    );
  }

  getPartName(part) {
    if (part.name) {
      /* This part has more than 1 step */
      return <span className="h3">{part.name}</span>;
    }
  }

  getParts() {
    return exercise.data.parts.map((part, i) => {
      return (
        <div key={i}>
          {this.getPartName(part)}
          {this.getPartLoopCount(part)}
          <ul>{this.getSteps(part)}</ul>
        </div>
      );
    });
  }

  getPartLoopCount(part) {
    if (part.num_of_loops > 1) {
      return (
        <span className="part_loop_count">
          Repeat {part.num_of_loops}
          {singleOrPlural(" time", part.num_of_loops)}
        </span>
      );
    }
  }

  getSteps(part) {
    return part.steps.map((step, i) => {
      return (
        <li key={i} className="exercise_step">
          {step.type} {this.getStepDuration(step)}{" "}
          {this.insertAirway(step.airway)}
        </li>
      );
    });
  }

  getStepDuration(step) {
    if (step.duration > 0) {
      return step.duration + singleOrPlural(" second", step.duration);
    } else {
      return "as long as you can";
    }
  }

  insertAirway(airway) {
    if (airway) {
      return <span className={airway}>{"trough " + airway}</span>;
    }
  }
}

function ExerciseStartControl(props) {
  return (
    <div id="exercise_start_control">
      <ExerciseDurationControl />
      <button
        className="btn"
        id="start_btn"
        onClick={() => props.startExercise()}
      >
        Start
      </button>
    </div>
  );
}

class ExerciseDurationControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: this.calcDuration(),
    };
  }

  render() {
    return (
      <div id="exercise_duration_control">
        {this.showDurationPreview()}
        <ExerciseSetCounter callback={() => this.updateDurationPreview()} />
      </div>
    );
  }

  calcDuration() {
    return formatTime(exercise.calcSecondsTillEnd()).txt;
  }

  updateDurationPreview() {
    this.setState({ duration: this.calcDuration() });
  }

  showDurationPreview() {
    if (!exercise.data.duration_undefined) {
      return (
        <div id="duration_preview">
          <span className="h4">Duration</span>
          <span>{this.state.duration}</span>
        </div>
      );
    }
  }
}

class ExerciseSetCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num_of_sets: exercise.data.num_of_sets,
    };
  }

  render() {
    return (
      <div id="exercise_set_control">
        <button
          className="btn btn_round btn_red"
          onClick={() => this.decrementSetCounter()}
        >
          -
        </button>
        <span>
          {this.state.num_of_sets}
          {singleOrPlural(" set", this.state.num_of_sets)}
        </span>
        <button
          className="btn btn_round btn_green"
          onClick={() => this.incrementSetCounter()}
        >
          +
        </button>
      </div>
    );
  }

  incrementSetCounter() {
    if (exercise.incrementSets()) {
      this.setState({ num_of_sets: exercise.data.num_of_sets });
      this.props.callback();
    }
  }

  decrementSetCounter() {
    if (exercise.decrementSets()) {
      this.setState({ num_of_sets: exercise.data.num_of_sets });
      this.props.callback();
    }
  }
}

class PracticeWindow extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    /* If user starts an exercise on mobile (small screen) then the animation wouldn't be displayed, because screen shows last scroll position */
  }

  render() {
    return (
      <section id="practice_window" className="section">
        <Animation />
        <PracticeControl closeExercise={() => this.props.closeExercise()} />
      </section>
    );
  }
}

function InExerciseSettings() {
  return (
    <div id="in_exercise_settings">
      <span className="h4">Settings</span>
      <SoundToggle />
      {exercise.data.name === "Power" && <BreathHoldFeedbackToggle />}
    </div>
  );
}

class Animation extends React.Component {
  componentDidMount() {
    animation.setUp(document.getElementById("breath_animation"), true);
  }

  render() {
    return (
      <Fade>
        <canvas id="breath_animation" width="265" height="265"></canvas>
      </Fade>
    );
  }
}

class PracticeControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cur_step_infinite: false,
      infinite_step_check_callback: exercise.addInfiniteStepCheckCallback(
        (is_infinite) => this.infiniteStepCheckCallback(is_infinite)
      ),
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.showBreathHoldControl()}
        <Fade>
          <div className="box" id="practice_control">
            <TimeLeft />
            <SetsLeft /> {/* Power exercise */}
            <PracticeFlowButtons
              closeExercise={() => this.props.closeExercise()}
              cur_step_infinite={this.state.cur_step_infinite}
            />
            <InExerciseSettings />
          </div>
        </Fade>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    const id = this.state.infinite_step_check_callback;
    exercise.removeInfiniteStepCheckCallback(id);
  }

  infiniteStepCheckCallback(is_infinite) {
    if (is_infinite !== this.state.cur_step_infinite) {
      /* Component state value differs from actual value */
      this.setState({ cur_step_infinite: is_infinite });
    }
  }

  showBreathHoldControl() {
    if (this.state.cur_step_infinite) {
      return (
        <BreathHoldControl cur_step_infinite={this.state.cur_step_infinite} />
      );
    }
  }
}

class SetsLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cur_set: 1,
      total_sets: exercise.data.num_of_sets,
      set_update_callback_ID: exercise.addSetUpdateCallback(() =>
        this.getCurrentSet()
      ),
    };
  }
  render() {
    if (exercise.data.duration_undefined) {
      return (
        <div id="sets_left_counter">
          <span className="h4">Current set</span>
          <span>
            {this.state.cur_set} of {this.state.total_sets}
          </span>
        </div>
      );
    } else {
      return "";
    }
  }

  componentWillUnmount() {
    exercise.removeSetUpdateCallback(this.state.set_update_callback_ID);
  }

  getCurrentSet() {
    this.setState({
      cur_set: exercise.cur_set + 1 /* Counting starts from 0 */,
    });
  }
}

class PracticeFlowButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercise_running: exercise.running,
    };
  }

  render() {
    return (
      <div>
        {this.showPauseOrResumeButton()}
        <button className="btn btn_red" onClick={() => this.quitExercise()}>
          Quit
        </button>
      </div>
    );
  }

  quitExercise() {
    this.props.closeExercise();
    exercise.stop();
  }

  pauseOrResume() {
    if (this.state.exercise_running) {
      exercise.pause();
    } else {
      exercise.resume();
    }

    this.setState({ exercise_running: !this.state.exercise_running });
  }

  showPauseOrResumeButton() {
    if (!this.props.cur_step_infinite) {
      if (exercise.running) {
        return (
          <button
            className="btn btn_yellow"
            id="pause_resume_btn"
            onClick={() => this.pauseOrResume()}
          >
            Pause
          </button>
        );
      } else {
        return (
          <button
            className="btn btn_green"
            id="pause_resume_btn"
            onClick={() => this.pauseOrResume()}
          >
            Resume
          </button>
        );
      }
    }
  }
}

const end_part_guide = new AudioSample("breathing/end_part_guide.mp3");
const counter_audio = getCounterAudio();
const minute_audio = new AudioSample("time/minute.mp3");
const minutes_audio = new AudioSample("time/minutes.mp3");
const half_minute_audio = new AudioSample("time/half_minute.mp3");
const breath_hold_ended_audio = new AudioSample(
  "breathing/breath_hold_end.mp3"
);
const PAUSE_LENGTH_MS = 600;

class BreathHoldControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter_s: 0,
      counter_controller: null,
    };
    this.half_minute_counter = 0;
  }

  render() {
    return (
      <Fade>
        <div className="box" id="breath_hold_control">
          <div id="breath_hold_counter">
            <span className="h4">Breath hold</span>
            <span>{formatTime(this.state.counter_s).txt}</span>
          </div>
          <button
            className="btn btn_green"
            id="end_infinite_part_btn"
            onClick={() => this.endHold()}
          >
            Inhale
          </button>
          <div
            id="double_click_area"
            onDoubleClick={() => this.endHold()}
          ></div>
        </div>
      </Fade>
    );
  }

  componentDidMount() {
    end_part_guide.play();
    this.startCounter();
  }

  componentWillUnmount() {
    end_part_guide.stop();
    clearInterval(this.state.counter_controller);
  }

  endHold() {
    breath_hold_ended_audio.play();
    exercise.endInfinitePart();
    this.endCounter();
  }

  startCounter() {
    /* Start counter */
    const controller = setInterval(() => {
      this.setState({ counter_s: this.state.counter_s + 1 });
      this.announceTime();
    }, SECOND);

    this.setState({ counter_controller: controller });
  }

  endCounter() {
    /* Update stats */
    breath_hold_data.update(this.state.counter_s);
    clearInterval(this.state.counter_controller);
  }

  announceTime() {
    if (settings_data.isHoldFeedbackEnabled()) {
      if (this.state.counter_s % 30 === 0) {
        this.half_minute_counter++;
        const time = formatTime(this.state.counter_s);

        if (time.minutes <= counter_audio.length) {
          /* Make sure that we have actual audio file for announcement */

          if (time.minutes > 0) {
            /* How many minutes elapsed? */
            counter_audio[time.minutes].play();

            setTimeout(() => {
              /* Pause after word */
              if (time.minutes === 1) {
                minute_audio.play();
              } else {
                minutes_audio.play();
              }
            }, PAUSE_LENGTH_MS);
          }

          if (this.half_minute_counter % 2 === 1) {
            /* Half a minute elapsed */
            setTimeout(() => {
              /* Pause after word */
              half_minute_audio.play();
            }, PAUSE_LENGTH_MS * 2);
          }
        }
      }
    }
  }
}

class TimeLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time_left: null,
      time_left_callback_id: exercise.addTimeLeftCallback((data) =>
        this.updateTimeLeft(data)
      ),
    };
  }

  render() {
    if (!exercise.data.duration_undefined) {
      return (
        <div className="data" id="time_left_box">
          <span className="h4">Time left</span>
          <span>{this.state.time_left}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  componentDidMount() {
    exercise.getTimeLeft();
  }

  componentWillUnmount() {
    const id = this.state.time_left_callback_id;
    exercise.removeTimeLeftCallback(id);
  }

  updateTimeLeft(time) {
    this.setState({ time_left: time });
  }
}

function ExerciseCompletedWindow(props) {
  return (
    <Fade>
      <section className="section">
        <button
          className="btn btn_red back_btn"
          onClick={() => props.closeExercise()}
        >
          Close
        </button>
        <div>
          <div className="box" id="exercise_completed_box">
            <span className="h1">Exercise completed</span>
            <StreakCounter />
          </div>
        </div>
      </section>
    </Fade>
  );
}

function Footer() {
  return (
    <footer id="footer" className="disclaimer">
      Information on this website is provided for informational purposes only
      and is not intended as a substitute for the advice provided by your
      physician or other healthcare professional. The author of this website is
      not liable or responsible for any errors contained on this website, or for
      any special, incidental, or consequential injury caused or alleged to be
      caused directly or indirectly by the information contained on this
      website.
    </footer>
  );
}

export default App;
