const SECOND = 1000; // 1 second in ms
class ScreenAwake {
  constructor() {
    this.video = document.querySelector("#keep_screen_awake_video");
    this.reset_timer = null;
  }

  start() {
    this.video.currentTime = 0;
    this.video.play();

    this.reset_timer = setInterval(() => {
      /* Rewind video to start every 30 seconds. Video is 1 minute long */
      this.video.currentTime = 0;
    }, 30 * SECOND);
  }

  stop() {
    clearInterval(this.reset_timer);
    this.video.pause();
  }
}

export { ScreenAwake };
